<template>
  <div class="a-open-box-new">
    <div class="a-show-box">
      <div @click="chooseItemOnClick(item)" :key="index" v-for="(item, index) in imgList" v-show="'item.lv != undefined'" class="img-item point"
        :class="[item.lv != undefined ? `bg-${item.lv}` : item.bean > 1 ? 'bg-1' : 'bg-4', { 'active-bg': chooseList.includes(item.id) }]">
        <div class="alias">{{ item.dura_alias }}</div>
        <div class="top-img-box">
          <img style="height:90%;" :src="item.cover" alt="">
        </div>
        <div class="name">
          {{ item.name }}
        </div>
        <div class="price">
          <Cint class="price-bean" :price="item.bean" />
        </div>
      </div>
    </div>
    <div class="bottom-box">
      <div class="cancel point" @click="cancelFun">
        <div style="margin-right:0.06rem">回收</div>
        <Cint class="cancel-price" :price="totalPrice" />
      </div>
      <div class="confirm point" @click="confirmFun">
        放入背包
      </div>
    </div>
  </div>
</template>

<script>
// 使用：盲盒开箱展示
export default {
  created() {
    //禁止滚动条滚动
    document.querySelector("html").classList.add("lock");
    window.addEventListener("mousewheel", this.forbidScroll);
    window.addEventListener("touchmove", this.forbidScroll, { passive: false });
  },
  data() {
    return {
      chooseList: []
    };
  },
  props: {
    imgList: {
      type: Array,
      default: () => {
        return [];
      }
    },
  },
  created() {
    this.imgList.forEach(item => {
      this.chooseList.push(item.id);
    });
  },
  computed: {
    totalPrice() {
      let JiaZhi = 0;
      for (let k = 0; k < this.chooseList.length; k++) {
        this.imgList.forEach(item => {
          if (item.id == this.chooseList[k]) {
            JiaZhi += Number(item.bean);
          }
        });
      }
      return JiaZhi.toFixed(2);
    }
  },
  methods: {
    chooseItemOnClick(item) {
      if (!this.chooseList.includes(item.id)) {
        this.chooseList.push(item.id);
      } else {
        this.chooseList.splice(this.chooseList.indexOf(item.id), 1);
      }
    },
    cancelFun() {
      this.$emit("cancelFun", this.chooseList);
    },
    confirmFun() {
      this.$emit("confirmFun", this.chooseList);
    }
  }
}
</script>

<style lang="scss">
.a-open-box-new {
  width: 100%;
  height: 100%;
  // background: url("../../assets/images/new/open-bg-new.png") no-repeat;
  background-size: 100% 100%;
  padding: 0.12rem 0.12rem 0.33rem 0.12rem;
  display: flex;
  align-content: space-between;
  justify-content: center;
  flex-wrap: wrap;

  .a-show-box {
    width: 100%;
    // width: 1.41rem;
    // height: 1.19rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.31rem;
    margin-bottom: 0.08rem;
    flex-wrap: wrap;

    .img-item {
      width: 0.95rem;
      height: 0.95rem;
      padding: 0.07rem 0.08rem;
      margin: 0 0.02rem;
      margin-bottom: 0.04rem;
      border: 0.02rem solid rgba(218, 174, 56, 0);
      .alias {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.1rem;
      }
      .top-img-box {
        width: 100%;
        height: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .name {
        margin-top: -0.06rem;
        font-size: 0.1rem;
        // max-width: 2rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0 0.08rem;
        text-align: center;
        margin-bottom: 0.04rem;
      }

      .price {
        // margin-top: 0.04rem;
        font-size: 0.1rem;
        // margin-bottom: 0.02rem;
      }
    }
    .active-bg {
      // border: 0.02rem solid rgba(218, 174, 56, 0.5);
    }

    .bg-1 {
       width: 1.41rem;
       height: 1.19rem;
      background: url("../../assets/newImages/open-bg1.png") no-repeat;
      background-size: 100% 100%;
       z-index: 99;
    }

    .bg-2 {
       width: 1.41rem;
       height: 1.19rem;
      background: url("../../assets/newImages/open-bg2.png") no-repeat;
      background-size: 100% 100%;
       z-index: 99;
    }

    .bg-3 {
       width: 1.41rem;
       height: 1.19rem;
      background: url("../../assets/newImages/open-bg3.png") no-repeat;
      background-size: 100% 100%;
       z-index: 99;
    }

    .bg-4 {
       width: 1.41rem;
       height: 1.19rem;
      background: url("../../assets/newImages/open-bg4.png") no-repeat;
      background-size: 100% 100%;
      z-index: 99;
    }
  }

  .bottom-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.2rem;

    .cancel {
      width: 0.91rem;
      height: 0.24rem;
      background: url("../../assets/newImages/user-itembg.png") no-repeat;
      background-size: 100% 100%;
      font-size: 0.1rem;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 0;
    }

    .confirm {
      width: 0.91rem;
      height: 0.24rem;
      background: url("../../assets/newImages/user-itembg.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      font-size: 0.1rem;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>