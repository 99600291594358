<template>
  <div class="Goods">
    <div class="Goods_box-list">
      <div class="Goods_list" >
        <!-- :class="{ 'Goods_item-active': active === item }" -->
        <div class="Goods_item"  :class="`lv${item.level}`"  @click="handleCurrentChange(item)" v-for="(item, index) in DetailList" :key="index"   >
          <div v-if="item.odds_percent" class="box-price">
            <div class="dura_alias">
              {{ item.odds_percent }}
            </div>
          </div>
          <div class="Goods_pic">
            <img :src="item.cover" alt="" />
          </div>
          <div class="elips1" style="font-size: 0.1rem;margin-top:0rem;margin-bottom:0.03rem;">{{ item.name }}</div>
          <div style="font-size: 0.1rem;margin-bottom:0.03rem;">
            {{ item.dura_alias == "无" ? "" : item.dura_alias }}
          </div>
          <div class="odds_percent">
            <div class="d-flex align-items-center">
              <Hprice :size="0.1" :price="item.bean" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Goods",
  props: {
    DetailList: {
      //箱内物品列表
      type: Array,
    },
  },
  data() {
    return {
      active: 0,
    };
  },

  created() {

  },
  methods: {
    
  },

};
</script>

<style lang="scss">

.Goods {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-content: space-between;
  flex-wrap: wrap;

  .Goods_box-list {
    width: 98%;
    background: #1D1300;
    box-sizing: border-box;
    // border: 0.01rem solid #69552D;


    .Goods_list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding: 0.08rem;
      
      .Goods_item {
        width: 1.00rem;
        height: 1.12rem;
        padding: 0.03rem 0.05rem 0.05rem;
        margin: 0 0.032rem;
        text-align: center;
        color: #fff;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        position: relative;
        margin-bottom: 0.08rem;
        background: url(../../assets/newImages/goods-list-bg.png) no-repeat;
        background-size: 100% 100%;

        .box-price {
          display: flex;
          justify-content: right;
          align-items: center;
          margin-top: 0.05rem;
        }

        .odds_percent {
          font-size: 0.1rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          text-align: left;
          box-sizing: border-box;
          color: #dea61f;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .dura_alias {
          box-sizing: border-box;
          color: #fff;
          font-size: 0.1rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          text-align: center;
        }

        .Goods_pic {
          width: 0.6rem;
          height: 0.46rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background-position: center;
          background-size: 100%;
          background-repeat: no-repeat;
          margin: 0 auto;
          img {
            width: 100%;
          }
        }

        p {
          width: 100%;
          box-sizing: border-box;
          font-size: 0.1rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-top: 0.18rem;
          margin-bottom: 0.08rem;
        }

        span {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #75cd66;
          font-size: 0.1rem;
          margin: 5px 0 0;

          img {
            width: 0.2rem;
          }
        }
      }
      .lv1 {
        background: url(../../assets/newImages/openbox-bg0.png) no-repeat center;
        background-size: 100% 100%;
      }

      .lv2 {
        background: url(../../assets/newImages/openbox-bg1.png) no-repeat center;
        background-size: 100% 100%;
      }

      .lv3 {
        background: url(../../assets/newImages/openbox-bg2.png) no-repeat center;
        background-size: 100% 100%;
      }

      .lv4 {
        background: url(../../assets/newImages/openbox-bg3.png) no-repeat center;
        background-size: 100% 100%;
      }

    }
  }
}
</style>
