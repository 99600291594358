<template>
  <div class="luckdrawX">
    <div class="openbg">
      <div class="prize_box">
        <div class="out">
          <div class="prize_box_roll" :class="{ active: StyBk == true }" :style="Topstyle[index]" style="display: flex" v-for="(i, index) of active" :key="index" :name="i">
            <div class="prize_box_roll-item" v-for="(item, index) in J_Arr[i - 1]" :key="index" :name="item?.siyecao">
              <div class="cont_pic" :class="`bg-img${item?.lv || item?.level}`">
                <img :src="item?.cover" alt="" srcset="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="left-float"></div>
    <div class="right-float"></div>
    <div v-if="!show" class="line-float" :class="{'line-float-5':active>3}"></div>
    <van-overlay @mousewheel.prevent.native="() => { }" :show="show" v-if="show">
      <div class="wrapper">
        <div class="Show_box2" :class="{'Show_box3':active>3}">
          <AOpenBox v-if="show" :imgList="ItemArr" @cancelFun="Postcash" @confirmFun="GuanBi" />
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<!-- 开一个箱子逻辑 -->
<script>
import { Cash, PersonalInfo } from "@/network/api.js";
import { mapMutations, mapState } from "vuex";
// import ASingleBox from "@/components/singleBox/ASingleBox.vue";
import AOpenBox from "@/components/singleBox/AOpenBoxNew.vue";
export default {
  components: {
    // ASingleBox,
    AOpenBox,
  },
  name: "luckdraw",
  props: {
    List: {
      type: Array,
    },
    ItemData: {
      type: Array,
    },
    active: {
      type: Number,
    },
    //开奖次数，触发开奖动画和结果
    CSnum: {
      type: Number,
    },
    // HeigHt:{
    // 	type:Number,default:1.65,
    // }
  },
  data() {
    return {
      J_Arr: [],
      Num1: 0,
      Num2: 0,
      Num3: 0,
      Num4: 0,
      Num5: 0,
      show: false,
      ItemArr: [],
      JiaZhi: 0,
      StyBk: true,
      KeyArr: [1, 2, 3, 4, 5],
      HeigHt: 0.63,
      HeigHtScoll: 0,
      Timer: null
    };
  },

  computed: {
    ...mapState(["mp3Start", 'mp3End', "soundStatus"]),
    Topstyle() {
      let array = [
        "transform: translateX(" + this.Num1 + "rem);",
        "transform: translateX(" + this.Num2 + "rem)",
        "transform: translateX(" + this.Num3 + "rem)",
        "transform: translateX(" + this.Num4 + "rem)",
        "transform: translateX(" + this.Num5 + "rem)",
      ];
      return array;
    },
  },
  beforeDestroy() {
    this.mp3Start && this.mp3Start.pause();
    this.mp3End && this.mp3End.pause();
    clearTimeout(this.Timer);
  },
  methods: {
    //拼接奖品并打乱
    SplicingArr(val) {
      this.J_Arr = [];
      for (let k = 0; k < this.active; k++) {
        let arr = [];
        if (val.length < 6) {
          for (let i = 0; i < 50; i++) {
            arr.push(...val);
          }
        } else if (val.length > 5 && val.length <= 13) {
          for (let i = 0; i < 11; i++) {
            arr.push(...val);
          }
        } else {
          for (let i = 0; i < 4; i++) {
            arr.push(...val);
          }
        }
        function randSort1(arr) {
          for (let i = 0, len = arr.length; i < len; i++) {
            let rand = parseInt(Math.random() * len);
            let temp = arr[rand];
            arr[rand] = arr[i];
            arr[i] = temp;
          }
          return arr;
        }
        this.J_Arr.push(randSort1(arr));
      }
    },

    // 确定中将
    ZhongJiang() {
      this.ItemArr = [];
      for (let j = 0; j < this.ItemData.length; j++) {
        this.J_Arr[j][50] = this.ItemData[j];
        this.J_Arr[j][50].siyecao = "四叶草";
        let key = "Select";
        let value = true;
        this.ItemData[j][key] = value;
        this.ItemArr.push(this.ItemData[j]);
      }

      const WidTh = 0.63;
      setTimeout(() => {
        let randomNum1 = (Math.random() * WidTh * 0.6) - WidTh * 0.6;
        this.Num1 = -(WidTh * 48 + randomNum1); //偏移值
        let randomNum2 = (Math.random() * WidTh * 0.6) - WidTh * 0.6;
        this.Num2 = -(WidTh * 48 + randomNum2); //偏移值
        let randomNum3 = (Math.random() * WidTh * 0.6) - WidTh * 0.6;
        this.Num3 = -(WidTh * 48 + randomNum3); //偏移值
        let randomNum4 = (Math.random() * WidTh * 0.6) - WidTh * 0.6;
        this.Num4 = -(WidTh * 48 + randomNum4); //偏移值
        let randomNum5 = (Math.random() * WidTh * 0.6) - WidTh * 0.6;
        this.Num5 = -(WidTh * 48 + randomNum5); //偏移值
        if (this.soundStatus) {
          this.mp3Start && this.mp3Start.play(); //播放声音
        }
      }, 500);
      this.Timer = setTimeout(() => {
        if (this.soundStatus) {
          this.mp3End && this.mp3End.play();
          setTimeout(() => {
            this.ChangeAudioStatus(true);
          }, 1000);
        }
        this.$emit("zhongjiang");
        this.show = true;
      }, 7700);
    },

    //默认全部选中
    Select_All() {
      for (let i = 0; i < this.ItemArr.length; i++) {
        let key = "Select";
        let value = true;
        this.ItemArr[i][key] = value;
        setTimeout(() => {
          this.$refs.Select[i].style.border = "1px solid #E9B10E";
        }, 10);
      }
      this.TotalPriceCal();
    },

    //选中
    SelectItem(item, index) {
      if (item.Select) {
        delete item.Select;
        this.$refs.Select[index].style.border = "1px solid transparent";
      } else {
        let key = "Select";
        let value = true;
        item[key] = value;
        this.$refs.Select[index].style.border = "1px solid #E9B10E";
      }
      this.TotalPriceCal();
    },

    // 计算回收总价
    TotalPriceCal() {
      this.JiaZhi = 0;
      let A_Arr = this.ItemArr.filter((v) => {
        return v.Select == true;
      });

      for (let k = 0; k < A_Arr.length; k++) {
        this.JiaZhi += Number(A_Arr[k].bean);
      }
    },

    //关闭遮罩层
    GuanBi() {
      this.show = false;
      this.StyBk = false;
      this.Num1 = 0;
      this.Num2 = 0;
      this.Num3 = 0;
      this.Num4 = 0;
      this.Num5 = 0;
      this.$emit("fromChild", false);
      for (let i = 0; i < this.J_Arr.length; i++) {
        this.J_Arr[i].unshift(this.ItemData[i]);
      }
      setTimeout(() => {
        this.StyBk = true;
      }, 500);
    },

    //筛选
    IsCover(item, index) {
      if (this.ItemData[index].Select == true) {
        this.ItemData[index].Select = false;
      } else {
        this.ItemData[index].Select = true;
      }
      this.KeyArr = this.KeyArr.map((item) => {
        return item + 1;
      });

      this.JiaZhi = 0;

      let ZJArr = this.ItemData.filter((i) => {
        return i.Select == true;
      });
      for (let i = 0; i < ZJArr.length; i++) {
        this.JiaZhi = this.JiaZhi + Number(ZJArr[i].bean);
      }
    },

    //回收
    Postcash(chooseList) {
      if (!chooseList || !chooseList.length) {
        this.$message({
          message: "请选择要回收的物品",
          type: "warning",
        });
        return false;
      }
      Cash(chooseList).then((res) => {
        this.$message({
          message: "回收成功",
          type: "success",
        });
        this.GetPersonalInfo();
        this.GuanBi();
      });
    },
    //个人信息
    GetPersonalInfo() {
      PersonalInfo().then((res) => {
        this.User(res.data.data);
      });
    },
    ...mapMutations(["User", "ChangeAudioStatus"])
  },

  watch: {
    List(val) {
      this.SplicingArr(this.List);
    },
    ItemData(val) {
      this.ItemData = val;
    },

    ItemArr(val) {
      this.JiaZhi = 0;
      for (let i = 0; i < val.length; i++) {
        this.JiaZhi = this.JiaZhi + Number(val[i].bean);
      }
    },

    active(num) {
      this.SplicingArr(this.List);
    },

    J_Arr(val) {
      this.J_Arr = val;
    },

    CSnum(num) {
      this.SplicingArr(this.List);
      this.ZhongJiang();
    },
  },
};
</script>

<style lang="scss" scoped>
.luckdrawX {
  width: 3.45rem;
  // height: 0.63rem;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 397;
  .left-float {
    width: 0.42rem;
    height: 100%;
    background: linear-gradient(90deg, #000000 28%, rgba(1, 3, 11, 0) 100%);
    position: absolute;
    z-index: 100;
    left: -0.19rem;
    top: 0rem;
  }
  .right-float {
    width: 0.42rem;
    height: 100%;
    background: linear-gradient(270deg, #000000 28%, rgba(1, 3, 11, 0) 100%);
    position: absolute;
    z-index: 100;
    right: -0.19rem;
    top: 0rem;
  }
  .line-float {
    width: 3.45rem;
    height: 140%;
    background: url(../../../assets/images/OpenBox/open-bg1-new.png) no-repeat
      center;
    background-size: 100% 100%;
    position: absolute;
    z-index: 110;
    left: 0rem;
    top: -20%;
  }
  .line-float-5 {
    height: 120%;
    top: -10%;
    background: url(../../../assets/images/OpenBox/open-bg1-new-5.png) no-repeat
      center;
    background-size: 100% 100%;
  }

  .openbg {
    .prize_box {
      position: relative;
      background: #000;
      .out {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        overflow: hidden;
        .prize_box_roll {
          height: 100%;
          margin-bottom: 0.03rem;
          margin-top: 0.03rem;
          &.active {
            transition: 7s cubic-bezier(0, 0, 0.28, 1);
          }
          .prize_box_roll-item {
            background: #333;
            width: 0.63rem;
            height: 0.63rem;
            flex-shrink: 0;
            position: relative;
            background-size: cover;
            box-sizing: border-box;
            border-right: 0.01rem solid #000;
            .cont_pic {
              width: 100%;
              height: 100%;
              background-position: center;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                width: 80%;
              }
            }
            .bg-img1 {
              background: url(../../../assets/images/public/back_pub-new1.png)
                no-repeat center;
              background-size: 100% 100%;
            }
            .bg-img2 {
              background: url(../../../assets/images/public/back_pub-new2.png)
                no-repeat center;
              background-size: 100% 100%;
            }
            .bg-img3 {
              background: url(../../../assets/images/public/back_pub-new3.png)
                no-repeat center;
              background-size: 100% 100%;
            }

            .bg-img4 {
              background: url(../../../assets/images/public/back_pub-new4.png)
                no-repeat center;
              background-size: 100% 100%;
            }
          }
        }
        .prize_box_roll:nth-child(1) {
          transition: all 7s cubic-bezier(0, 0, 0.7, 1.01) 0s;
        }

        .prize_box_roll:nth-child(2) {
          transition: all 7s cubic-bezier(0, 0, 0.5, 1.01) 0s;
        }

        .prize_box_roll:nth-child(3) {
          transition: all 7s cubic-bezier(0, 0, 0.4, 1) 0s;
        }

        .prize_box_roll:nth-child(4) {
          transition: all 7s cubic-bezier(0, 0, 0.6, 1.01) 0s;
        }

        .prize_box_roll:nth-child(5) {
          transition: all 7s cubic-bezier(0, 0, 0.3, 1) 0s;
        }
      }
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .Show_box2 {
      width: 3.35rem;
      height: 1.86rem;
      .Show_title {
        text-align: center;
        font-size: 12px;
      }
    }
    .Show_box3 {
      width: 3.35rem;
      height: 3.2rem;
      display: flex;
      flex-wrap: wrap;
      .Show_title {
        text-align: center;
        font-size: 12px;
      }
      .a-open-box-new {
        padding-top: 0.36rem;
      }
    }
  }
}
</style>
